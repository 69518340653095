import { useAuth0 } from '@auth0/auth0-react';
import { Avatar, Box, Stack } from '@mui/material';
import MenuLogo from 'assets/img/menuLogo.png';
import YellowBorderButton from 'components/buttons/YellowBorderButton';
import YellowButton from 'components/buttons/YellowButton';

import { MAIN_BOX_SHADOW } from 'utils/theme';
import ManageSubscription from './components/ManageSubscription';
import UserMenu from './components/UserMenu';

const NavBar = () => {
  const { loginWithRedirect, isAuthenticated, user } = useAuth0();

  return (
    <Box
      height="100vh"
      bgcolor="white"
      display="flex"
      flexDirection="column"
      alignItems="center"
      boxShadow={MAIN_BOX_SHADOW}
      position="fixed"
      left={0}
      top={0}
      zIndex={1000}
      width="inherit"
    >
      <Box marginBottom="20px" marginTop="40px">
        <img
          src={MenuLogo}
          alt="navbarLogo"
          width="65px"
          style={{ borderRadius: '8px' }}
        />
      </Box>
      {isAuthenticated && (
        <Box>
          <Avatar
            src={user?.picture}
            sx={{ width: '50px', height: '50px', marginTop: '20px' }}
          />
          <UserMenu />
        </Box>
      )}
      {isAuthenticated ? (
        <Stack
          spacing={2}
          marginTop="auto"
          marginBottom="80px"
          alignItems="center"
        >
          <ManageSubscription />
        </Stack>
      ) : (
        <Stack spacing={2} marginTop="auto" marginBottom="40px">
          <YellowBorderButton onClick={() => loginWithRedirect()}>
            Sign Up
          </YellowBorderButton>
          <YellowButton onClick={() => loginWithRedirect()}>
            Log In
          </YellowButton>
        </Stack>
      )}
    </Box>
  );
};

export default NavBar;
