import { SvgIcon } from '@mui/material';

const CalendarIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 25 25">
      <path
        d="M20.5 9.08594H4.5M7.5 3.08594V5.08594M17.5 3.08594V5.08594M6.5 21.0859H18.5C19.6046 21.0859 20.5 20.1905 20.5 19.0859V7.08594C20.5 5.98137 19.6046 5.08594 18.5 5.08594H6.5C5.39543 5.08594 4.5 5.98137 4.5 7.08594V19.0859C4.5 20.1905 5.39543 21.0859 6.5 21.0859Z"
        stroke="#8289A7"
        fill="none"
        strokeLinejoin="round"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};

export default CalendarIcon;
