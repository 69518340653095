import { ALLOWED_ORIGINS } from 'config';
import React, { useEffect } from 'react';

type MessageData = { type: string; payload: { key: string; value: string } };

type MessageReceiverProps = {
  onMessage: (message: string) => void;
};

// Type guard to check if data matches MessageData type
const isMessageData = (data: unknown): data is MessageData => {
  if (typeof data !== 'object' || data === null) {
    console.warn('Invalid message: data is not an object or is null:', data);
    return false;
  }

  // Use Record<string, unknown> instead of any for more type safety
  const obj = data as Record<string, unknown>;

  if (obj.type !== 'setText') {
    console.warn(
      `Invalid message type: expected 'setText', received '${obj.type}'`
    );
    return false;
  }

  if (typeof obj.payload !== 'object' || obj.payload === null) {
    console.warn(
      'Invalid message: payload is not an object or is null:',
      obj.payload
    );
    return false;
  }

  const payload = obj.payload as Record<string, unknown>;

  if (payload.key !== 'text') {
    console.warn(
      `Invalid payload key: expected 'text', received '${payload.key}'`
    );
    return false;
  }

  if (typeof payload.value !== 'string') {
    console.warn(
      `Invalid payload value: expected 'string', received '${typeof payload.value}'`
    );
    return false;
  }

  return true;
};

const SetTextMessageReceiver: React.FC<MessageReceiverProps> = ({
  onMessage,
}) => {
  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      // Validate the message's origin
      if (!ALLOWED_ORIGINS.includes(event.origin)) {
        console.warn('Received message from untrusted origin:', event.origin);
        return;
      }

      // Validate if the data matches MessageData type
      const data = event.data;

      if (isMessageData(data)) {
        console.log('Valid message received:', data);
        onMessage(data.payload.value);
      } else {
        console.warn('Ignoring message with unexpected format or type:', data);
      }
    };

    // Add the event listener for 'message'
    window.addEventListener('message', handleMessage);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [onMessage]);

  return <></>;
};

export default SetTextMessageReceiver;
