import SvgIcon from '@mui/material/SvgIcon';

const SearchIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 19 19">
      <path
        d="M13.1262 12.7739L17.9651 17.3281M8.0889 14.7376C12.0233 14.7376 15.2127 11.7358 15.2127 8.03289C15.2127 4.32995 12.0233 1.32812 8.0889 1.32812C4.15453 1.32812 0.965088 4.32995 0.965088 8.03289C0.965088 11.7358 4.15453 14.7376 8.0889 14.7376Z"
        stroke={props.stroke ? props.stroke : '#6F6F6F60'}
        fill="none"
        strokeOpacity="0.6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default SearchIcon;
