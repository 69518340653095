import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useAppSelector } from 'core/store/hooks';
import useDateFilter from 'hooks/useDateFilter';
import { SourceDates } from 'pages/PlaygroundV2Page/helpers';
import { ChangeEvent } from 'react';
import { DARKER_GREY } from 'utils/theme';

const RadioButton = ({ value, label }: { value: string; label: string }) => {
  return (
    <FormControlLabel
      value={value}
      control={<Radio sx={{ py: 0.5 }} />}
      label={label}
      sx={{ color: DARKER_GREY }}
    />
  );
};

const DateFilter = () => {
  const selectedSourceDate = useAppSelector(
    (state) => state.claimsV2.selectedSourceDate
  );

  const { updateClaimByDate } = useDateFilter();

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    updateClaimByDate((event.target as HTMLInputElement).value as SourceDates);
  };

  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="date-radio-buttons-group-label"
        value={selectedSourceDate}
        name="date-buttons-group"
        onChange={onChange}
      >
        <RadioButton value={SourceDates.MOST_RECENT} label="Most recent" />
        <RadioButton value={SourceDates.LEAST_RECENT} label="Least recent" />
      </RadioGroup>
    </FormControl>
  );
};

export default DateFilter;
