import {
  filteredClaimResults,
  setClaimLoading,
  setClaimResults,
  setIsNoDataFound,
  setLinearProgressValue,
  setSources,
} from 'core/store/claimsV2Slice';
import { useDispatch } from 'react-redux';
import { stanceDetectionRequest } from 'utils/API/stanceDetection';

export const useCheckClaim = () => {
  const dispatch = useDispatch();

  const checkClaim = (searchValue: string) => {
    if (searchValue.length === 0) return;
    dispatch(setClaimLoading(true));
    stanceDetectionRequest(searchValue, true)
      .then((response) => {
        dispatch(setClaimResults(response.data));
        if (response.data.evidence.length) {
          dispatch(setSources(response.data.evidence));
          dispatch(filteredClaimResults(response.data));
          dispatch(setIsNoDataFound(false));
        } else {
          dispatch(setIsNoDataFound(true));
        }
      })
      .finally(() => {
        setTimeout(() => dispatch(setClaimLoading(false)), 1000);
        dispatch(setLinearProgressValue(100));
      });
  };

  return checkClaim;
};
