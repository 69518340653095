import { Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';
import {
  filteredClaimResults,
  setSelectedSearchEngines,
} from 'core/store/claimsV2Slice';
import { useAppSelector } from 'core/store/hooks';
import { searchEngines } from 'pages/PlaygroundV2Page/helpers';
import { useDispatch } from 'react-redux';
import { DARKER_GREY } from 'utils/theme';

const SearchEngineFilter = () => {
  const dispatch = useDispatch();
  const selectedSearchEngines = useAppSelector(
    (state) => state.claimsV2.selectedSearchEngines
  );

  const claim = useAppSelector((state) => state.claimsV2.claim);
  const filteredClaim = useAppSelector((state) => state.claimsV2.filteredClaim);
  const claimSearchEngines = claim.evidence?.map((e) => e.searchEngine ?? '');

  const updateSearchEngines = (searchEngine: string) =>
    selectedSearchEngines.includes(searchEngine)
      ? selectedSearchEngines.filter((value) => value !== searchEngine)
      : [...selectedSearchEngines, searchEngine];

  return (
    <Stack direction="column">
      {searchEngines.map((searchEngine, i) => {
        const searchEnginesLength =
          claimSearchEngines?.filter((e) => e === searchEngine).length ?? 0;

        return (
          <FormControlLabel
            key={i}
            checked={selectedSearchEngines.includes(searchEngine)}
            onChange={() => {
              const updatedSearchEngines = updateSearchEngines(searchEngine);

              const filteredClaimEvidenceByEngine = claim.evidence?.filter(
                (evidence) =>
                  updatedSearchEngines.includes(evidence.searchEngine ?? '')
              );

              // update selected engines
              dispatch(setSelectedSearchEngines(updatedSearchEngines));

              // update filtered claim sources
              dispatch(
                filteredClaimResults({
                  ...filteredClaim,
                  evidence: filteredClaimEvidenceByEngine,
                })
              );
            }}
            control={<Checkbox sx={{ py: '4px', px: 1 }} />}
            label={
              <Stack direction="row" spacing={1}>
                <Typography variant="subtitle1" color={DARKER_GREY}>
                  {searchEngine}
                </Typography>
                {searchEnginesLength > 0 && (
                  <Typography variant="subtitle1" color={DARKER_GREY}>
                    ({searchEnginesLength})
                  </Typography>
                )}
              </Stack>
            }
          />
        );
      })}
    </Stack>
  );
};

export default SearchEngineFilter;
