import { Box, Stack } from '@mui/material';
import { useAppSelector } from 'core/store/hooks';
import { useMemo } from 'react';
import { SourceTypes } from '../helpers';
import ClaimEvidence from './components/ClaimEvidence';
import ClaimSummary from './components/ClaimSummary';

const Claim = () => {
  const filteredClaim = useAppSelector((state) => state.claimsV2.filteredClaim);
  const sortedSources = useAppSelector((state) => state.claimsV2.sources);
  const selectedSortingAccordionName = useAppSelector(
    (state) => state.claimsV2.selectedSortingAccordionName
  );

  const sortedOrFilteredSources = useMemo(() => {
    if (selectedSortingAccordionName === SourceTypes.ALL) {
      return filteredClaim.evidence ?? [];
    } else {
      return sortedSources;
    }
  }, [filteredClaim.evidence, selectedSortingAccordionName, sortedSources]);

  return (
    <Box>
      {filteredClaim && <ClaimSummary claim={filteredClaim} />}
      <Stack spacing={2} pb={2}>
        {sortedOrFilteredSources?.map((source, index) => (
          <ClaimEvidence index={index} evidence={source} key={index} />
        ))}
      </Stack>
    </Box>
  );
};

export default Claim;
