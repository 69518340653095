import { Stack } from '@mui/material';
import { useAppSelector } from 'core/store/hooks';
import { MAIN_BOX_SHADOW } from 'utils/theme';
import LinearProgressBar from './components/LinearProgressBar';
import {
  SearchFieldBody,
  SearchFieldFooter,
} from './components/SearchFieldComponents';

const SearchField = () => {
  const claimLoading = useAppSelector((state) => state.claimsV2.claimLoading);

  return (
    <Stack mt={4}>
      <Stack boxShadow={MAIN_BOX_SHADOW} borderRadius={claimLoading ? 0 : 3}>
        <SearchFieldBody />
        <SearchFieldFooter />
      </Stack>
      <LinearProgressBar />
    </Stack>
  );
};

export default SearchField;
