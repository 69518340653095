import { Stack, StackProps } from '@mui/material';
import { ReactNode } from 'react';
import { BORDER_COLOR, MAIN_BOX_SHADOW } from 'utils/theme';

interface Props extends StackProps {
  children: ReactNode;
}

const Card = ({ children, ...props }: Props) => {
  return (
    <Stack
      mt={2}
      p={5}
      bgcolor="white"
      border={`1px solid ${BORDER_COLOR}`}
      borderRadius="12px"
      boxShadow={MAIN_BOX_SHADOW}
      {...props}
    >
      {children}
    </Stack>
  );
};

export default Card;
