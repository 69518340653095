import React from 'react';
import { Stack, Typography } from '@mui/material';
import { setSearchValue } from 'core/store/claimsV2Slice';
import SuggestedButton from '../../SuggestedClaimButtons/components/SuggestedButton';
import { useCheckClaim } from 'pages/PlaygroundV2Page/hooks/useCheckClaim';
import { useDispatch } from 'react-redux';

interface SuggestedSearchesProps {
  suggestedSearchesData?: string[];
}

const SuggestedSearches: React.FC<SuggestedSearchesProps> = ({
  suggestedSearchesData,
}) => {
  const checkClaim = useCheckClaim();
  const dispatch = useDispatch();
  const handleClaimClick = (claim: string) => {
    dispatch(setSearchValue(claim));
    checkClaim(claim);
  };

  const buttonStyles = {
    border: '1px solid #6C727A4D',
    hoverBackgroundColor: 'white',
    hoverBorderColor: '#6F6F6F99',
    hoverStrokeColor: '#545454',
  };

  return (
    <Stack
      ml={4}
      direction="column"
      useFlexGap
      spacing={{ xs: 3 }}
      alignItems="flex-start"
    >
      <Typography variant="h6">Suggested searches:</Typography>

      {suggestedSearchesData?.map((claim, index) => (
        <SuggestedButton
          key={index}
          claim={claim.substring(0, claim.length - 1)}
          onClick={() => handleClaimClick(claim)}
          {...buttonStyles}
        />
      ))}
    </Stack>
  );
};

export default SuggestedSearches;
