import {
  Stack,
  Typography,
  Link,
  useMediaQuery,
  Tooltip,
  Button,
  Box,
} from '@mui/material';
import React, { useState } from 'react';
import {
  LARGE_FONT,
  DARK_GREY,
  SMALL_FONT,
  ISMOBILE_BREAKPOINT,
  MEDIUM_FONT,
} from 'utils/theme';
import SourceLink from 'components/SourceLink/SourceLink';
import Favicon from 'components/Favicon';
import FeedbackSource from 'components/FeedbackSource';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useEvidenceLabel } from 'pages/PlaygroundV2Page/hooks/useEvidendeLabel';

interface SourceProps {
  claim: Claim;
  source: Source;
  onFeedbackSourceSupport: OnFeedbackSourceSupport;
  onFeedbackSourceRelevance: OnFeedbackSourceRelevance;
}

const Source: React.FC<SourceProps> = ({
  claim,
  source,
  onFeedbackSourceSupport,
  onFeedbackSourceRelevance,
}) => {
  const { getColor } = useEvidenceLabel();
  const color = getColor(source);

  const [showFullSnippet, setShowFullSnippet] = useState(false);

  const isMobile = useMediaQuery(ISMOBILE_BREAKPOINT);

  return (
    <Stack>
      <Stack
        direction="row"
        spacing={1}
        justifyContent="space-between"
        alignItems="center"
        mb="-3px"
        mt="-3px"
      >
        <Stack direction="row" spacing={1} alignItems="center">
          {source.domain && (
            <>
              <Favicon
                rootDomain={source.domain}
                width={24}
                height={24}
                color={color}
              />
              <Tooltip title={'Visit source article on ' + source.domain} arrow>
                <Typography>
                  <Link
                    href={source.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    underline="none"
                    sx={{ color: 'black' }}
                    overflow="hidden"
                  >
                    {source.domain}
                  </Link>
                </Typography>
              </Tooltip>
            </>
          )}
          {!isMobile && (
            <FeedbackSource
              claim={claim}
              source={source}
              onFeedbackSourceSupport={onFeedbackSourceSupport}
              onFeedbackSourceRelevance={onFeedbackSourceRelevance}
            />
          )}
        </Stack>
        {!isMobile && <SourceLink searchEngine={source.searchEngine} />}
      </Stack>
      {isMobile && (
        <Stack
          direction="row"
          ml="12px"
          pl={2}
          alignItems="center"
          sx={{ borderLeft: `2px solid ${color}` }}
        >
          <FeedbackSource
            claim={claim}
            source={source}
            onFeedbackSourceSupport={onFeedbackSourceSupport}
            onFeedbackSourceRelevance={onFeedbackSourceRelevance}
          />
          <SourceLink searchEngine={source.searchEngine} />
        </Stack>
      )}

      <Stack
        ml="12px"
        p={2}
        sx={{ borderLeft: `2px solid ${color}` }}
        alignItems="flex-start"
        spacing={1}
      >
        <Typography
          fontSize={isMobile ? MEDIUM_FONT : LARGE_FONT}
          fontWeight="bold"
          pb={2}
        >
          {source.title}
        </Typography>
        <Typography
          fontSize={isMobile ? MEDIUM_FONT : LARGE_FONT}
          sx={{ color: DARK_GREY, overflowWrap: 'break-word', width: '100%' }}
        >
          {showFullSnippet ||
          (source.snippet?.length !== undefined &&
            source.snippet?.length <= 350)
            ? source.snippet
            : source.snippet?.slice(0, 350) + ' ...'}
        </Typography>
        {source.snippet?.length !== undefined &&
          source.snippet.length > 350 && (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                direction: 'row',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                onClick={() => {
                  setShowFullSnippet(!showFullSnippet);
                }}
                sx={{
                  marginBottom: '1em',
                  height: '2em',
                }}
                endIcon={
                  showFullSnippet ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )
                }
                disableRipple
              >
                {showFullSnippet ? 'Show less' : 'Show more'}
              </Button>
            </Box>
          )}
        <Typography fontSize={SMALL_FONT} pb={2} sx={{ color: DARK_GREY }}>
          {source.publishDate &&
            'Published ' + new Date(source.publishDate).toLocaleDateString()}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default Source;
