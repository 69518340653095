import { setScrollTarget } from 'core/store/claimsV2Slice';
import { useAppSelector } from 'core/store/hooks';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

export const useSrollToClaims = () => {
  const dispatch = useDispatch();
  const scrollTargetRef = useRef<HTMLDivElement | null>(null);

  const scrollTargetId = useAppSelector(
    (state) => state.claimsV2.scrollTargetId
  );

  useEffect(() => {
    if (scrollTargetId === 'scrollToClaim' && scrollTargetRef.current) {
      scrollTargetRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
    setTimeout(() => {
      dispatch(setScrollTarget(''));
    }, 1000);
  }, [dispatch, scrollTargetId]);

  const handleScrollToClaim = () => {
    dispatch(setScrollTarget('scrollToClaim'));
  };

  return { handleScrollToClaim, scrollTargetRef };
};

export default useSrollToClaims;
