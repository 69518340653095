import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AppState {
  loginModalOpen: boolean;
}

const initialState: AppState = {
  loginModalOpen: false,
};

const loginSlice = createSlice({
  name: 'loginFilter',
  initialState,
  reducers: {
    setLoginModalOpen: (state: AppState, action: PayloadAction<boolean>) => ({
      ...state,
      loginModalOpen: action.payload,
    }),
  },
});

export const { setLoginModalOpen } = loginSlice.actions;
export default loginSlice.reducer;
