import CheckIcon from '@mui/icons-material/Check';
import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { useAppSelector } from 'core/store/hooks';

const SummaryText = () => {
  const summary = useAppSelector((state) => state.claimsV2.claim.summary);
  const claimLoading = useAppSelector((state) => state.claimsV2.claimLoading);

  return (
    <Box>
      {claimLoading ? (
        <Box>
          <Skeleton height={80} />
          <Skeleton height={80} />
          <Skeleton height={80} />
        </Box>
      ) : (
        <Box>
          {summary && summary.length !== 0 ? (
            summary.map((sum, key) => (
              <Stack alignItems="flex-start" direction="row" mb={2} key={key}>
                <Box paddingX="6px" pt="4px">
                  <CheckIcon
                    sx={{ color: '#6C727A80', width: '18px', height: '18px' }}
                  />
                </Box>
                <Typography fontSize="18px">{sum}</Typography>
              </Stack>
            ))
          ) : (
            <Typography sx={{ paddingLeft: '30px', fontSize: '18px' }} pt="4px">
              Summary isn’t available for this content at the moment. Please
              review the content down bellow for detailed information.
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

export default SummaryText;
