import { useAuth0 } from '@auth0/auth0-react';
import { Logout } from '@mui/icons-material';
import { Avatar, Box, Divider, Menu, Typography } from '@mui/material';
import { MouseEvent, useCallback, useState } from 'react';
import { DARKER_GREY, DIM_GRAY } from 'utils/theme';
import MenuOpenButton from './components/MenuOpenButton';
import UserMenuItem from './components/UserMenuItem';

const UserMenuStyles = {
  elevation: 0,
  sx: {
    overflow: 'visible',
    borderRadius: '8px',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.22))',
    mt: 1.5,
    '& .MuiAvatar-root': {
      width: 26,
      height: 26,
      ml: -0.5,
      mr: 1.5,
    },
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 43,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
};

const UserMenu = () => {
  const { logout, user } = useAuth0();
  const handleLogout = useCallback(() => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
    setAnchorEl(null);
  }, [logout]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <MenuOpenButton onClick={handleClick} open={open} />
      <Menu
        anchorEl={anchorEl}
        id="user-menu"
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: UserMenuStyles,
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <UserMenuItem
          onClick={handleClose}
          icon={<Avatar src={user?.picture} />}
          title={
            <Typography
              color={DARKER_GREY}
              fontWeight={600}
              sx={{ opacity: 0.7 }}
            >
              {user?.name}
            </Typography>
          }
        />
        <Divider />
        <UserMenuItem
          onClick={handleLogout}
          icon={
            <Logout fontSize="small" sx={{ color: DIM_GRAY, opacity: 0.6 }} />
          }
          title="Logout"
        />
      </Menu>
    </Box>
  );
};

export default UserMenu;
