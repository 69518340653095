import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Link, Typography } from '@mui/material';
import {
  SnackbarKey,
  useSnackbar,
  VariantType,
  WithSnackbarProps,
} from 'notistack';
import React from 'react';

let useSnackbarRef: WithSnackbarProps;
export const SnackbarUtilsConfigurator: React.FC = () => {
  useSnackbarRef = useSnackbar();
  return null;
};

let currentMessage = '';

const close = (key: SnackbarKey | undefined) => (
  <IconButton
    onClick={() => {
      useSnackbarRef.closeSnackbar(key);
      currentMessage = '';
    }}
  >
    <CloseIcon />
  </IconButton>
);

const toast = (
  msg: string,
  variant: VariantType = 'default',
  duration?: number
) => {
  if (msg === currentMessage) return;
  currentMessage = msg;
  useSnackbarRef.enqueueSnackbar(msg, {
    variant,
    autoHideDuration: duration ? duration : 5000,
    preventDuplicate: true,
  });

  setTimeout(
    () => {
      currentMessage = '';
    },
    duration ? duration : 7000
  );
};

const showSuccess = (msg: string, duration?: number) => {
  toast(msg, 'success', duration);
};

const showInfo = (msg: string, duration?: number) => {
  toast(msg, 'info', duration);
};

const showWarning = (msg: string, duration?: number) => {
  toast(msg, 'warning', duration);
};

const showError = (msg: string, linkText?: string, linkUrl?: string) => {
  if (msg === currentMessage) return;
  currentMessage = msg;
  useSnackbarRef.enqueueSnackbar(
    <Typography>
      {msg}{' '}
      <Link
        href={linkUrl}
        target="_blank"
        rel="noopener noreferrer"
        sx={{ color: 'white', textDecoration: 'underline' }}
      >
        {linkText}
      </Link>
    </Typography>,
    {
      variant: 'error',
      persist: true,
      action: close,
    }
  );
};

export { showError, showInfo, showSuccess, showWarning };
