import { useAuth0 } from '@auth0/auth0-react';
import { ReplayOutlined } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Box,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import Sources from 'components/Sources';
import useSettings from 'hooks/useSettings';
import useUserMetaData from 'hooks/useUserMetaData';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  addClaim,
  deleteClaim,
  setNoClaimsFound,
  updateClaim,
} from 'core/store/claimsSlice';
import ClaimSkeleton from 'components/ClaimSkeleton';
import ClaimLabel from './components/ClaimLabel';
import MissingSources from './components/MissingSources';
import {
  feedbackSourceRelevanceRequest,
  feedbackSourceSupportRequest,
} from 'utils/API/feedback';
import { Deployment, useDeployment } from 'hooks/useDeployment';
import { stanceDetectionRequest } from 'utils/API/stanceDetection';

interface ClaimProps {
  claim: Claim;
  sourceFilter: SourceFilter;
}

const Claim: React.FC<ClaimProps> = ({ claim, sourceFilter }) => {
  const dispatch = useDispatch();
  const { user } = useAuth0();
  const { noSubscription } = useUserMetaData();
  const { allowTracking } = useSettings();
  const { deployment } = useDeployment();

  const onFeedbackSourceSupport: OnFeedbackSourceSupport = useCallback(
    (claim: Claim, source: Source, userAgrees: boolean) => {
      feedbackSourceSupportRequest(
        claim.claim,
        user?.email ?? '',
        userAgrees,
        claim?.language ?? 'en',
        source,
        allowTracking
      );
    },
    [allowTracking, user?.email]
  );

  const onFeedbackSourceRelevance: OnFeedbackSourceRelevance = useCallback(
    (claim: Claim, source: Source, userAgrees: boolean) => {
      feedbackSourceRelevanceRequest(
        claim.claim,
        user?.email ?? '',
        userAgrees,
        claim?.language ?? 'en',
        source,
        allowTracking
      );
    },
    [allowTracking, user?.email]
  );

  return (
    <Box px={4} py={2}>
      <Stack spacing={1}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={1}
        >
          <ClaimLabel label={claim.claim} />
          <Tooltip title="Remove claim" arrow>
            <IconButton
              size="small"
              onClick={() => dispatch(deleteClaim(claim))}
            >
              <ClearIcon />
            </IconButton>
          </Tooltip>
        </Stack>
        {claim.summary?.length !== 0 && (
          <Typography fontStyle="italic">Summary:</Typography>
        )}
        {(() => {
          if (claim.summary !== undefined && claim.summary.length !== 0) {
            return claim.summary.map((item, index) => (
              <Typography key={index}>- {item}</Typography>
            ));
          }
        })()}
        {deployment !== Deployment.conference && claim.fix && (
          <>
            <Typography fontStyle="italic">Verdict:</Typography>
            <Typography>{claim.fix}</Typography>
          </>
        )}
        {claim.isBeingChecked ? (
          <ClaimSkeleton />
        ) : claim.evidence ? (
          <Stack spacing={2}>
            <Sources
              claim={claim}
              sources={claim.evidence}
              sourceFilter={sourceFilter}
              onFeedbackSourceSupport={onFeedbackSourceSupport}
              onFeedbackSourceRelevance={onFeedbackSourceRelevance}
            />
          </Stack>
        ) : (
          !noSubscription && (
            <Stack direction="row" spacing={1} alignItems="center">
              <Skeleton animation={false} height="3em" width="100%" />
              <Tooltip title="Retry request" arrow>
                <IconButton
                  onClick={() => {
                    dispatch(deleteClaim(claim));
                    dispatch(addClaim({ claim: claim.claim }));
                    dispatch(
                      updateClaim({
                        claim: claim.claim,
                        isBeingChecked: true,
                      })
                    );
                    dispatch(setNoClaimsFound(false));
                    stanceDetectionRequest(
                      claim.claim,
                      allowTracking,
                      claim.resolved_claim
                    )
                      .then((response) => {
                        dispatch(
                          updateClaim({
                            claim: claim.claim,
                            id: response.data._id,
                            evidence: response.data.evidence,
                            finalScore: response.data.finalScore,
                            // TODO: set this in a finally or catch in case there is an error
                            isBeingChecked: false,
                            hasBeenChecked: true,
                            summary: response.data.summary,
                            fix: response.data.fix,
                          })
                        );
                      })
                      .catch((error) => {
                        console.error(error);
                        dispatch(
                          updateClaim({
                            claim: claim.claim,
                            id: undefined,
                            evidence: undefined,
                            finalScore: undefined,
                            // TODO: set this in a finally or catch in case there is an error
                            isBeingChecked: false,
                            hasBeenChecked: true,
                          })
                        );
                      });
                  }}
                  size="small"
                >
                  <ReplayOutlined />
                </IconButton>
              </Tooltip>
            </Stack>
          )
        )}
      </Stack>
      <MissingSources claim={claim} />
    </Box>
  );
};

export default Claim;
