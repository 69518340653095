import {
  Box,
  Divider,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Sum from 'components/Sum';
import React, { useEffect, useState } from 'react';
import {
  BG_COLOR,
  DISPUTED,
  ISMOBILE_BREAKPOINT,
  MIXED,
  SMALL_FONT,
  SUPPORTED,
} from 'utils/theme';
import { useAppSelector } from 'core/store/hooks';

const filterMapping = {
  1: {
    displayAll: false,
    displaySupported: true,
    displayMixed: false,
    displayDisputed: false,
  },
  2: {
    displayAll: false,
    displaySupported: false,
    displayMixed: true,
    displayDisputed: false,
  },
  3: {
    displayAll: false,
    displaySupported: false,
    displayMixed: false,
    displayDisputed: true,
  },
  default: {
    displayAll: true,
    displaySupported: true,
    displayMixed: true,
    displayDisputed: true,
  },
};

export const getClaimStatus = (claim: Claim) => {
  const { finalScore } = claim;
  if (finalScore === undefined || finalScore === null) {
    return 'undefined';
  } else if (finalScore >= 0.6) {
    return 'supported';
  } else if (finalScore > 0.4 && finalScore < 0.6) {
    return 'mixed';
  } else {
    return 'disputed';
  }
};

export type ClaimCount = {
  total: number;
  supported: number;
  mixed: number;
  disputed: number;
};

interface FilterByStanceProps {
  /** Allows this component to change which claims are shown */
  setFilter: (newFilter: ClaimFilter) => void;
}

/**
 * Lets the user display only supported/mixed/disputed claims or all
 *
 * @return {JSX.Element}
 */
export const FilterByStance: React.FC<FilterByStanceProps> = ({
  setFilter,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const isMobile = useMediaQuery(ISMOBILE_BREAKPOINT);
  const [claimCount, setClaimCount] = useState<ClaimCount>({
    total: 0,
    supported: 0,
    mixed: 0,
    disputed: 0,
  });

  const claims = useAppSelector((state) => state.claims.claims);

  useEffect(() => {
    if (claims === undefined) return;
    const claimCounts = claims.reduce(
      (acc, claim) => {
        const status = getClaimStatus(claim);
        acc[status]++;
        return acc;
      },
      { total: claims.length, supported: 0, mixed: 0, disputed: 0 }
    );
    setClaimCount(claimCounts);
  }, [claims]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
    setFilter(filterMapping[newValue] || filterMapping.default);
  };

  const filterByStanceValues = [
    {
      title: 'All',
      tooltipName: 'Show all claims',
      color: BG_COLOR,
      count: claimCount.total,
      isSum: true,
    },
    {
      title: 'Supported',
      tooltipName: 'Show only supported claims',
      color: SUPPORTED,
      count: claimCount.supported,
      isSum: true,
    },
    {
      title: 'Mixed',
      tooltipName: 'Show only mixed claims',
      color: MIXED,
      count: claimCount.mixed,
      isSum: true,
    },
    {
      title: 'Disputed',
      tooltipName: 'Show only disputed claims',
      color: DISPUTED,
      count: claimCount.disputed,
      isSum: true,
    },
  ];

  return (
    <Box minWidth={'auto'} width={isMobile ? '100vw' : 'auto'}>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        orientation={'horizontal'}
        sx={{
          minWidth: 0,
          float: 'none',
          maxWidth: isMobile ? '96vw' : 'inherit',
        }}
        scrollButtons="auto"
        variant="fullWidth"
      >
        {filterByStanceValues.map((value, key) => (
          <Tooltip title={value.tooltipName} key={key} arrow>
            <Tab
              sx={{
                alignItems: 'center',
                paddingLeft: isMobile ? 0 : 'auto',
                padding: isMobile ? 0 : 'auto',
              }}
              label={
                <Stack
                  direction={isMobile ? 'column' : 'row'}
                  spacing={0.5}
                  alignItems="center"
                >
                  <Sum color={value.color} count={value.count} isSum={true} />
                  <Typography fontSize={SMALL_FONT}>{value.title}</Typography>
                </Stack>
              }
              disableRipple
            />
          </Tooltip>
        ))}
      </Tabs>
      <Divider />
    </Box>
  );
};

export default FilterByStance;
