import { Button, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import { PieChart } from '@mui/x-charts';
import Sum from 'components/Sum';
import { useAppSelector } from 'core/store/hooks';
import { SourceTypes } from 'pages/PlaygroundV2Page/helpers';
import useScoreCount from 'pages/PlaygroundV2Page/hooks/useScoreCount';
import useScrollToClaims from 'pages/PlaygroundV2Page/hooks/useScrollToClaims';
import { LIGHT_GREEN, LIGHT_PINK, LIGHT_YELLOW } from 'utils/theme';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const palette = [LIGHT_GREEN, LIGHT_YELLOW, LIGHT_PINK];

const pieParams = { height: 200, margin: { right: 5 } };

const Donut = () => {
  const claimLoading = useAppSelector((state) => state.claimsV2.claimLoading);
  const evidence = useAppSelector((state) => state.claimsV2.claim.evidence);

  const { supportingSources, mixedSources, disputingSources } =
    useScoreCount(evidence);

  const donutData = [
    {
      id: 1,
      value: supportingSources?.length ?? 0,
      title: SourceTypes.SUPPORTING,
      tooltip: `We found ${
        supportingSources?.length ?? 0
      } sources which are generally supporting this claim.`,
    },
    {
      id: 2,
      value: mixedSources?.length ?? 0,
      title: SourceTypes.MIXED,
      tooltip: `We found ${
        mixedSources?.length ?? 0
      } sources which have a mixed position on this claim.`,
    },
    {
      id: 3,
      value: disputingSources?.length ?? 0,
      title: SourceTypes.DISPUTING,
      tooltip: `We found ${
        disputingSources?.length ?? 0
      } sources which are generally disputing this claim.`,
    },
  ];

  const series = [{ data: donutData, innerRadius: 50, outerRadius: 70 }];

  const CustomLegend = ({ data, palette }) => {
    const { handleScrollToClaim } = useScrollToClaims();

    return (
      <Stack gap={1} alignItems="flex-start">
        {data.map((entry, index) =>
          entry.value === 0 && !claimLoading ? null : (
            <Stack direction="row" gap={1} key={entry.id}>
              {claimLoading ? (
                <Skeleton width={200} height={30} />
              ) : (
                <Stack direction="row" alignItems="center">
                  <Sum
                    color={palette[index % palette.length]}
                    count={entry.value}
                    isSum={true}
                  />
                  <Button
                    variant="text"
                    onClick={handleScrollToClaim}
                    sx={{
                      textTransform: 'capitalize',
                      textDecoration: 'underline',
                      color: 'black',
                      justifyContent: 'flex-start',
                      '&:hover': {
                        background: 'transparent',
                      },
                    }}
                  >
                    <Typography variant="subtitle1">{entry.title}</Typography>
                  </Button>
                  <Typography>links found</Typography>
                  <Tooltip title={entry.tooltip} arrow>
                    <HelpOutlineIcon
                      sx={{
                        color: '#6C727A80',
                        width: '18px',
                        height: '18px',
                        paddingLeft: '4px',
                      }}
                    />
                  </Tooltip>
                </Stack>
              )}
            </Stack>
          )
        )}
      </Stack>
    );
  };

  return (
    <Stack spacing={2} alignItems="center">
      {claimLoading ? (
        <Skeleton variant="circular" width={150} height={150} />
      ) : (
        <PieChart colors={palette} series={series} {...pieParams} />
      )}
      <CustomLegend data={donutData} palette={palette} />
    </Stack>
  );
};

export default Donut;
