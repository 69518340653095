import { IconButton, Typography } from '@mui/material';
import DiamondIcon from 'assets/img/DiamondIcon';
import { useNavigate } from 'react-router-dom';
import { DARKER_GREY, VANILLA_CREAM, YELLOW } from 'utils/theme';

const GetProButton = ({ title }: { title?: string }) => {
  const navigate = useNavigate();

  return (
    <IconButton
      onClick={() => {
        navigate('/pricing');
      }}
      sx={{
        background: VANILLA_CREAM,
        border: `1px solid ${YELLOW}`,
        borderTopLeftRadius: title ? '8px' : '20px',
        borderTopRightRadius: title ? '10px' : '20px',
        borderBottomRightRadius: title ? '10px' : '20px',
        borderBottomLeftRadius: '0px',
        px: 1,
        py: 0,
      }}
    >
      <DiamondIcon
        sx={{ fontSize: '1rem', right: '2px', position: 'relative' }}
      />
      {title && (
        <Typography variant="body2" color={DARKER_GREY}>
          {title}
        </Typography>
      )}
    </IconButton>
  );
};

export default GetProButton;
