import { Stack } from '@mui/material';
import YellowBorderButton from 'components/buttons/YellowBorderButton';
import YellowButton from 'components/buttons/YellowButton';
import {
  filteredClaimResults,
  setOpenFilterPopover,
  setSelectedSearchEngines,
  setSelectedSourceDate,
  setSelectedSourceTypes,
} from 'core/store/claimsV2Slice';
import { useAppSelector } from 'core/store/hooks';
import {
  searchEngines,
  SourceDates,
  sourceTypeValues,
} from 'pages/PlaygroundV2Page/helpers';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

const PopoverFooter = () => {
  const dispatch = useDispatch();
  const claim = useAppSelector((state) => state.claimsV2.claim);

  const resetFilters = useCallback(() => {
    dispatch(setSelectedSearchEngines(searchEngines));
    dispatch(setSelectedSourceTypes(sourceTypeValues));
    dispatch(setSelectedSourceDate(SourceDates.MOST_RECENT));

    dispatch(filteredClaimResults(claim));
  }, [claim, dispatch]);

  return (
    <Stack
      direction="row"
      spacing={1}
      left="70px"
      position="relative"
      pb={3}
      pt={2}
    >
      <YellowButton
        onClick={() => {
          dispatch(setOpenFilterPopover(false));
        }}
      >
        Apply
      </YellowButton>
      <YellowBorderButton
        onClick={() => {
          resetFilters();
          dispatch(setOpenFilterPopover(false));
        }}
      >
        Reset Filters
      </YellowBorderButton>
    </Stack>
  );
};

export default PopoverFooter;
