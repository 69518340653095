import { Box, Link, Stack, Typography } from '@mui/material';
import React from 'react';
import Favicon from 'components/Favicon';
import SeeMoreButton from 'components/SeeMoreButton';
import moment from 'moment';
import { BORDER_COLOR, DARKER_GREY, LIGHT_BLACK } from 'utils/theme';
import { useEvidenceLabel } from 'pages/PlaygroundV2Page/hooks/useEvidendeLabel';
import SourceLabel from 'pages/PlaygroundV2Page/NoSourcesFoundComponent/components/SourceLabel';
import ReadAloudButton from './ReadAloudButton';
import CopyToClipboardButton from '../../../Summary/components/CopyToClipboardButton';

export const ClaimEvidenceHeader = ({
  index,
  evidence,
}: {
  index: number;
  evidence: Source;
}) => {
  const { getLabel } = useEvidenceLabel();

  const label = getLabel(evidence);
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      mt={3}
      spacing={1}
    >
      <Typography fontSize="18px" fontWeight="500" my={2}>
        <Box
          component="span"
          fontWeight="400"
          fontSize="16px"
          paddingRight="4px"
        >
          {index + 1}.
        </Box>{' '}
        {evidence.title}
      </Typography>
      <Box
        sx={{
          padding: '12px',
        }}
      >
        <SourceLabel label={label} />
      </Box>
    </Stack>
  );
};

export const ClaimEvidenceBody = ({ evidence }: { evidence: Source }) => {
  const textToRead = `${evidence.title ?? ''}. ${
    evidence.evidenceSnippet ?? ''
  }`;
  const textToCopy = evidence.url ?? '';
  return (
    <Box
      sx={{
        background: 'rgba(241, 242, 251, 0.6)',
        borderRadius: '8px',
        marginLeft: '20px !important',
        marginRight: '20px !important',
        padding: '24px 117px 24px 40px',
      }}
    >
      <SeeMoreButton
        name={evidence.title ?? ''}
        text={evidence.evidenceSnippet ?? ''}
      />
      <ReadAloudButton text={textToRead} />
      <CopyToClipboardButton textToCopy={textToCopy} />
    </Box>
  );
};

export const ClaimEvidenceFooter = ({ evidence }: { evidence: Source }) => {
  return (
    <Stack direction="row" pl="24px" alignItems="center" spacing="10px">
      {evidence.domain && (
        <Favicon rootDomain={evidence.domain} width={24} height={24} />
      )}
      <Link
        sx={{
          pr: '10px',
          borderRight: `1px solid ${BORDER_COLOR}`,
          fontSize: '12px',
        }}
        href={evidence.url}
        target="_blank"
      >
        {evidence.domainName ?? evidence.domain}
      </Link>
      {evidence.publishDate && (
        <Typography
          pr="10px"
          fontSize="12px"
          borderRight={`1px solid ${BORDER_COLOR}`}
          color={DARKER_GREY}
        >
          {moment(evidence.publishDate).format('MMMM')}{' '}
          {moment(evidence.publishDate).year()}
        </Typography>
      )}
      {(evidence.citationCount ?? 0) > 0 && (
        <>
          <Typography
            fontSize="12px"
            pr="10px"
            borderRight={`1px solid ${BORDER_COLOR}`}
            color={DARKER_GREY}
          >
            <span
              style={{
                textDecoration: 'underline',
                color: LIGHT_BLACK,
              }}
            >
              {evidence.citationCount}
            </span>{' '}
            citations
          </Typography>
        </>
      )}
      {evidence.authors?.length ? (
        <Typography
          fontSize="12px"
          pr="10px"
          borderRight={`1px solid ${BORDER_COLOR}`}
          color={DARKER_GREY}
        >
          <span>{evidence.authors[0]}</span>{' '}
          {evidence.authors.length > 1 && (
            <>
              +{' '}
              <span
                style={{
                  textDecoration: 'underline',
                  color: LIGHT_BLACK,
                }}
              >
                {evidence.authors.length - 1} authors
              </span>
            </>
          )}
        </Typography>
      ) : null}
      <Typography fontSize="12px">{evidence.searchEngine}</Typography>
    </Stack>
  );
};
