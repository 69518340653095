import PopoverBody from './components/PopoverBody';
import PopoverComponent from './components/PopoverComponent';
import PopoverFooter from './components/PopoverFooter';
import PopoverHeader from './components/PopoverHeader';

const Filter = () => {
  return (
    <PopoverComponent>
      <PopoverHeader />
      <PopoverBody />
      <PopoverFooter />
    </PopoverComponent>
  );
};

export default Filter;
