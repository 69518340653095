import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setSearchValue } from 'core/store/claimsV2Slice';
import SuggestedButton from './components/SuggestedButton';
import { useCheckClaim } from '../hooks/useCheckClaim';

const SuggestedClaimButtons: React.FC = () => {
  const checkClaim = useCheckClaim();
  const dispatch = useDispatch();
  const [suggestedClaims, setSuggestedClaims] = useState<string[]>([]);

  useEffect(() => {
    axios
      .get('https://mock.factiverse.ai/v1/editor')
      .then((response) => {
        setSuggestedClaims(response.data.suggestedClaims);
      })
      .catch((error: string) => {
        console.error(error);
      });
  }, []);

  const handleClaimClick = (claim: string) => {
    dispatch(setSearchValue(claim));
    checkClaim(claim);
  };
  return (
    <Stack
      mt={5}
      direction="row"
      flexWrap="wrap"
      useFlexGap
      spacing={{ xs: 3 }}
      justifyContent="flex-start"
    >
      {suggestedClaims?.map((claim, index) => (
        <SuggestedButton
          key={index}
          claim={claim.substring(0, claim.length - 1)}
          onClick={() => handleClaimClick(claim)}
        />
      ))}
    </Stack>
  );
};

export default SuggestedClaimButtons;
