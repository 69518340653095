import SvgIcon from '@mui/material/SvgIcon';

const CopyIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 8 8">
      <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.1484 6.29395H3.89844C3.55326 6.29395 3.27344 6.57377 3.27344 6.91895V15.6689C3.27344 16.0141 3.55326 16.2939 3.89844 16.2939H15.1484C15.4936 16.2939 15.7734 16.0141 15.7734 15.6689V6.91895C15.7734 6.57377 15.4936 6.29395 15.1484 6.29395Z"
          stroke="#8289A7"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.77344 3.79395H17.6484C17.8142 3.79395 17.9732 3.85979 18.0904 3.977C18.2076 4.09421 18.2734 4.25319 18.2734 4.41895V13.7939"
          stroke="#8289A7"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default CopyIcon;
