import { ListItemIcon, MenuItem } from '@mui/material';

interface UserMenuItemProps {
  onClick: () => void;
  icon: JSX.Element;
  title: string | JSX.Element;
}

const UserMenuItem = ({ onClick, icon, title }: UserMenuItemProps) => {
  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon>{icon}</ListItemIcon>
      {title}
    </MenuItem>
  );
};

export default UserMenuItem;
