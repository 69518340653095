import { Box, Stack, Typography } from '@mui/material';
import SortingAccordion from './SortingAccordion';
import Filter from 'pages/PlaygroundV2Page/Filter';

const ClaimSummary = ({ claim }: { claim: Claim }) => (
  <Box
    sx={{
      background: 'white',
      border: '1px solid #E8EAEB',
      px: '30px',
      paddingTop: 3,
      borderTopLeftRadius: '12px',
      borderTopRightRadius: '12px',
      mb: 2,
      mt: 3,
      position: 'sticky',
      top: 0,
      backgroundColor: 'white',
      zIndex: 1,
    }}
  >
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Stack spacing={2}>
        <Typography fontSize="20px">{claim.claim}</Typography>
        {claim.evidence && <SortingAccordion evidence={claim.evidence} />}
      </Stack>
      <Filter />
    </Stack>
  </Box>
);

export default ClaimSummary;
