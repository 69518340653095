import { Box, Divider, Stack } from '@mui/material';
import Card from '../components/Card';
import Donut from './components/Donut';
import SummaryFooter from './components/SummaryFooter';
import SummaryText from './components/SummaryText';
import SummaryHeader from './components/SummaryHeader';

const Summary = () => {
  return (
    <Card paddingX="30px" paddingY="25px">
      <Stack
        direction="row"
        spacing={6}
        divider={<Divider orientation="vertical" flexItem />}
      >
        <Box flex={1.25}>
          <Stack spacing={2}>
            <SummaryHeader />
            <SummaryText />
            <SummaryFooter />
          </Stack>
        </Box>
        <Box flex={1}>
          <Donut />
        </Box>
      </Stack>
    </Card>
  );
};

export default Summary;
