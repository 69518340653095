import { Stack, Tooltip, Typography } from '@mui/material';
import { CLOUD_MIST, LAVENDER_STEEL } from 'utils/theme';

const SummaryHeader = () => {
  return (
    <Stack direction="row" alignItems="center" spacing={2} paddingX="30px">
      <Typography sx={{ fontSize: '18px' }}>Summary</Typography>
      <Tooltip
        title="We summarize the main points of the found sources with the help of generative AI."
        arrow
      >
        <Typography
          sx={{
            width: '49px',
            backgroundColor: CLOUD_MIST,
            color: LAVENDER_STEEL,
            fontSize: '12px',
            borderRadius: '4px',
            textAlign: 'center',
          }}
        >
          Beta
        </Typography>
      </Tooltip>
    </Stack>
  );
};

export default SummaryHeader;
