import React, { useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import StopIcon from '@mui/icons-material/Stop';
import ReadAloudIcon from 'assets/img/ReadAloudIcon';

interface ReadAloudButtonProps {
  text: string;
}

const ReadAloudButton: React.FC<ReadAloudButtonProps> = ({ text }) => {
  const [isReading, setIsReading] = useState(false);

  const handleReadAloud = () => {
    if (isReading) {
      window.speechSynthesis.cancel();
      setIsReading(false);
    } else {
      const utterance = new SpeechSynthesisUtterance(text);
      utterance.lang = 'en-US';
      utterance.rate = 1;
      utterance.pitch = 1;

      utterance.onend = () => {
        setIsReading(false);
      };

      window.speechSynthesis.cancel();
      window.speechSynthesis.speak(utterance);
      setIsReading(true);
    }
  };

  return (
    <Tooltip title={isReading ? 'Stop' : 'Read Aloud'}>
      <IconButton onClick={handleReadAloud} sx={{ marginLeft: '-8px' }}>
        {isReading ? (
          <StopIcon sx={{ height: '20px', color: '#8289A7' }} />
        ) : (
          <ReadAloudIcon data-testid="VolumeUpIcon" />
        )}
      </IconButton>
    </Tooltip>
  );
};

export default ReadAloudButton;
