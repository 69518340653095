import { Stack } from '@mui/material';
import { useAppSelector } from 'core/store/hooks';
import { extractSummaryTextFromClaim } from 'pages/PlaygroundV2Page/helpers';
import CopyToClipboardButton from './CopyToClipboardButton';
import ReadAloudButton from '../../Claim/components/ClaimEvidence/ReadAloudButton';

const SummaryFooter = () => {
  const claimLoading = useAppSelector((state) => state.claimsV2.claimLoading);
  const claim = useAppSelector((state) => state.claimsV2.claim);
  const summary = useAppSelector((state) => state.claimsV2.claim.summary);

  const textToCopy = extractSummaryTextFromClaim(claim);
  const summaryText = Array.isArray(summary) ? summary.join(' ') : '';

  if (!summary || claimLoading || summary.length === 0) {
    return null;
  }

  return (
    <Stack direction="row" alignItems="center" width="100%" paddingX="30px">
      <ReadAloudButton text={summaryText} />
      <CopyToClipboardButton textToCopy={textToCopy} />
    </Stack>
  );
};

export default SummaryFooter;
