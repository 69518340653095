import { IconButton, Tooltip, Typography } from '@mui/material';
import DiamondIcon from 'assets/img/DiamondIcon';
import { ReactElement } from 'react';
import { LIGHT_BLACK, VANILLA_CREAM, YELLOW } from 'utils/theme';

interface DiamondButtonProps {
  onClick: () => void;
  tooltipTitle?: string;
  buttonTitle?: string;
}

interface TooltipIconProps {
  tooltipTitle: string;
  children: ReactElement;
}

const TooltipIcon = ({ tooltipTitle, children }: TooltipIconProps) => {
  return tooltipTitle ? (
    <Tooltip title={tooltipTitle} arrow>
      {children}
    </Tooltip>
  ) : (
    children
  );
};

const DiamondButton = ({
  onClick,
  tooltipTitle,
  buttonTitle,
}: DiamondButtonProps) => {
  return (
    <TooltipIcon tooltipTitle={tooltipTitle ?? ''}>
      <IconButton
        sx={{
          background: VANILLA_CREAM,
          border: `1.5px solid ${YELLOW}`,
          width: buttonTitle ? 'inherit' : '61px',
          borderRadius: '32px',
        }}
        onClick={onClick}
      >
        <DiamondIcon sx={{ fontSize: '1.5rem' }} />
        {buttonTitle && (
          <Typography marginLeft="6px" color={LIGHT_BLACK}>
            {buttonTitle}
          </Typography>
        )}
      </IconButton>
    </TooltipIcon>
  );
};

export default DiamondButton;
