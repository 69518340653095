import { createTheme } from '@mui/material/styles';
export const theme = createTheme({
  typography: {
    fontFamily: 'Inter',
    allVariants: {
      color: '#212427',
    },
  },
  components: {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: '#4D91FF',
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: '#4D91FF',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#545454',
          fontSize: '12px',
          lineHeight: '140%',
          maxWidth: '250px',
          wordWrap: 'break-word',
        },
      },
    },
  },
});

export const SMALL_FONT = '0.75rem';
export const MEDIUM_FONT = '0.875rem';
export const BIG_FONT = '0.938rem';
export const LARGE_FONT = '1.125rem';
export const TITLE_FONT = '3rem';

export const YELLOW = '#F8C408';
export const LIGHT_GREY = '#F5F7F8';
export const RED = '#ff1010';
export const DARK_GREY = '#6c727a';
export const BG_COLOR = '#fafbfc';
export const BORDER_COLOR = '#e8eaeb';

export const SUPPORTED = '#d7fbcd';
export const MIXED = '#fbe593';
export const DISPUTED = '#fee7df';
export const FOUNDCLAIM = '#E7E7E7';

export const LIGHT_PINK = '#FEE0EB';
export const LIGHT_YELLOW = '#F8E59E';
export const LIGHT_GREEN = '#DEFAD1';
export const GREY = '#878787';
export const LIGHT_VIOLET = '#8389a7';
export const BRIGHT_BLUE = '#4D91FF';
export const BRIGHT_YELLOW = '#f8c406';
export const LIGHT_BLACK = '#212427';
export const DARKER_GREY = '#545454';
export const PALE_YELLOW = '#feedb4';
export const LAVENDER_STEEL = '#8289A7';
export const CLOUD_MIST = '#f1f2fb';
export const DIM_GRAY = '#6F6F6f';
export const SOFT_BLUE = '#F2F7FE';
export const VANILLA_CREAM = '#FDF2C7';
export const GOLDEN_HONEY = '#ffe275';
export const ENTITY = '#A0ABFF';

export const BORDER_RADIUS = '36px';

export const ISMOBILE_BREAKPOINT = '(max-width:1024px)';

export const MAIN_BOX_SHADOW = '4px 4px 18px rgba(128,155,181,0.1)';
