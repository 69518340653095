import { useEffect, useState } from 'react';

export enum Deployment {
  // Local development
  development = 'development',
  // Test deployment at test-editor.factiverse.ai
  testing = 'testing',
  // Main production deployment at editor.factiverse.ai
  production = 'production',
  // Deployment for Vinay's reasearch paper at factcheckeditor.com
  conference = 'conference',
  // DNB pilot at pilot.factiverse.ai
  pilot = 'pilot',
}

export const useDeployment = () => {
  const [deployment, setDeployment] = useState<Deployment>(
    Deployment.development
  );

  useEffect(() => {
    const environment = process.env.REACT_APP_DEPLOYMENT;
    switch (environment) {
      case 'development':
        setDeployment(Deployment.development);
        break;
      case 'testing':
        setDeployment(Deployment.testing);
        break;
      case 'production':
        setDeployment(Deployment.production);
        break;
      case 'conference':
        setDeployment(Deployment.conference);
        break;
      case 'pilot':
        setDeployment(Deployment.pilot);
        break;

      default:
        break;
    }
  }, []);

  return { deployment };
};
