import SvgIcon from '@mui/material/SvgIcon';

const ReadAloudIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 8 8">
      <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.02295 13.1689H3.27295C3.10719 13.1689 2.94822 13.1031 2.83101 12.9859C2.7138 12.8687 2.64795 12.7097 2.64795 12.5439V7.54395C2.64795 7.37819 2.7138 7.21921 2.83101 7.102C2.94822 6.98479 3.10719 6.91895 3.27295 6.91895H7.02295L12.6479 2.54395V17.5439L7.02295 13.1689Z"
          stroke="#8289A7"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.7705 8.16895V11.9189"
          stroke="#8289A7"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.2705 6.91895V13.1689"
          stroke="#8289A7"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default ReadAloudIcon;
