import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Button } from '@mui/material';
import { MouseEvent } from 'react';
import { CLOUD_MIST, LAVENDER_STEEL } from 'utils/theme';

const ArrowIconStyles = {
  color: LAVENDER_STEEL,
  height: '18px',
  width: '18px',
};

interface MenuOpenButtonProps {
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  open: boolean;
}

const MenuOpenButton = ({ onClick, open }: MenuOpenButtonProps) => {
  return (
    <Button
      sx={{
        background: CLOUD_MIST,
        height: '17px',
        width: '17px',
        borderRadius: '28px',
        minWidth: '18px',
        position: 'relative',
        left: '34px',
        bottom: '18px',
        '&:hover': {
          background: CLOUD_MIST,
        },
      }}
      onClick={onClick}
    >
      {open ? (
        <KeyboardArrowUpIcon sx={ArrowIconStyles} fontSize="small" />
      ) : (
        <KeyboardArrowDownIcon sx={ArrowIconStyles} fontSize="small" />
      )}
    </Button>
  );
};

export default MenuOpenButton;
