import { SvgIcon } from '@mui/material';

const DiamondIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 25 25">
      <path
        d="M21.291 10.1268H3.29102M10.791 5.12683L9.29102 10.1268L12.291 20.1268L15.291 10.1268L13.791 5.12683M3.82294 10.7179L11.5477 19.3009C11.945 19.7423 12.6371 19.7423 13.0343 19.3009L20.7591 10.7179C21.0705 10.3719 21.102 9.85677 20.835 9.47543L18.0896 5.55337C17.9025 5.28604 17.5967 5.12683 17.2704 5.12683H7.31167C6.98536 5.12683 6.67957 5.28604 6.49244 5.55337L3.747 9.47543C3.48006 9.85677 3.51154 10.3719 3.82294 10.7179Z"
        stroke="#545454"
        fill="none"
        strokeLinejoin="bevel"
      />
    </SvgIcon>
  );
};

export default DiamondIcon;
