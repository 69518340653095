import { Tooltip, Typography } from '@mui/material';

const ClaimLabel = ({ label }) => {
  return (
    <div>
      {label.length <= 200 ? (
        <Typography
          fontWeight="bold"
          sx={{
            overflowWrap: 'break-word',
          }}
        >
          {label}
        </Typography>
      ) : (
        <Tooltip title={label} arrow>
          <Typography
            fontWeight="bold"
            sx={{
              overflowWrap: 'break-word',
            }}
          >
            {label.slice(0, 200) + '...'}
          </Typography>
        </Tooltip>
      )}
    </div>
  );
};

export default ClaimLabel;
