import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import {
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  filteredClaimResults,
  setClaimResults,
  setIsNoDataFound,
  setLinearProgressValue,
  setSearchValue,
  setSelectedSearchEngines,
} from 'core/store/claimsV2Slice';
import { useAppSelector } from 'core/store/hooks';
import useUserMetaData from 'hooks/useUserMetaData';
import {
  getErrorText,
  isTooLongText,
  searchEngines,
} from 'pages/PlaygroundV2Page/helpers';
import { useCheckClaim } from 'pages/PlaygroundV2Page/hooks/useCheckClaim';
import { useDispatch } from 'react-redux';
import { GREY, RED } from 'utils/theme';
import GetProButton from './GetProButton';

export const SearchFieldBody = () => {
  const dispatch = useDispatch();
  const claimLoading = useAppSelector((state) => state.claimsV2.claimLoading);
  const searchValue = useAppSelector((state) => state.claimsV2.searchValue);
  const filteredClaim = useAppSelector((state) => state.claimsV2.filteredClaim);
  const checkClaim = useCheckClaim();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event?.target.value;
    dispatch(setSearchValue(value));
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      if (!isTooLongText(searchValue) && searchValue.length > 0) {
        checkClaim(searchValue);
      }
    }
  };

  return (
    <TextField
      id="outlined-basic"
      placeholder="Write or paste your text here"
      variant="outlined"
      sx={{
        background: 'white',
        height: claimLoading || filteredClaim.evidence ? undefined : '175px',
        paddingX: '30px',
        paddingY: '25px',
        '& fieldset': { border: 'none' },
        borderTopRightRadius: '12px',
        borderTopLeftRadius: '12px',
        border: '1px solid #E8EAEB',
        borderBottom: '0px',
        borderBottomRightRadius: '0px',
        borderBottomLeftRadius: '0px',
      }}
      value={searchValue}
      multiline
      onChange={handleInputChange}
      onKeyDown={handleKeyDown}
      InputProps={{
        style: {
          padding: 0,
          fontSize: '18px',
          color: '#212427',
        },
        endAdornment: (
          <InputAdornment position="end">
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              divider={<Divider orientation="vertical" flexItem />}
            >
              {searchValue.length > 0 && (
                <IconButton
                  type="button"
                  sx={{
                    border: '1px solid #6C727A30',
                    width: '30px',
                    height: '30px',
                  }}
                  aria-label="delete claim"
                  onClick={() => {
                    dispatch(setSearchValue(''));
                    dispatch(setClaimResults({} as Claim));
                    dispatch(filteredClaimResults({} as Claim));
                    dispatch(setLinearProgressValue(0));
                    dispatch(setIsNoDataFound(false));
                    dispatch(setSelectedSearchEngines(searchEngines));
                  }}
                >
                  <CloseIcon sx={{ color: '#8289A7' }} />
                </IconButton>
              )}
              <IconButton
                type="button"
                sx={{
                  border: '1px solid #6C727A30',
                  width: '42px',
                  height: '42px',
                }}
                aria-label="search"
                disabled={isTooLongText(searchValue)}
                onClick={() => checkClaim(searchValue)}
              >
                <SearchIcon sx={{ color: '#8289A7' }} />
              </IconButton>
            </Stack>
          </InputAdornment>
        ),
      }}
    />
  );
};

export const SearchFieldFooter = () => {
  const searchValue = useAppSelector((state) => state.claimsV2.searchValue);
  const claimLoading = useAppSelector((state) => state.claimsV2.claimLoading);
  const { noOrTrialSubscription } = useUserMetaData();

  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      sx={{
        background: 'white',
        borderBottomRightRadius: claimLoading ? '0px' : '12px',
        borderBottomLeftRadius: claimLoading ? '0px' : '12px',
        border: `1px solid #E8EAEB`,
        paddingY: '12px',
        paddingX: '30px',
      }}
      alignItems="center"
    >
      <Typography
        variant="subtitle2"
        sx={{
          color: isTooLongText(searchValue) ? RED : GREY,
          pr: 1,
        }}
      >
        {getErrorText(searchValue) ?? 'Check single claims of up to 30 words'}
      </Typography>
      {noOrTrialSubscription && <GetProButton title="Get Pro" />}
    </Stack>
  );
};
