import {
  filteredClaimResults,
  setSelectedSourceDate,
} from 'core/store/claimsV2Slice';
import { useAppSelector } from 'core/store/hooks';
import { sortSourcesByDate, SourceDates } from 'pages/PlaygroundV2Page/helpers';
import { useDispatch } from 'react-redux';

export const useDateFilter = () => {
  const dispatch = useDispatch();
  const filteredClaim = useAppSelector((state) => state.claimsV2.filteredClaim);

  const updateClaimByDate = (sourceDate: SourceDates) => {
    const newSourcesSortedByDate = sortSourcesByDate(
      filteredClaim.evidence ?? [],
      sourceDate
    );
    dispatch(setSelectedSourceDate(sourceDate));
    dispatch(
      filteredClaimResults({
        ...filteredClaim,
        evidence: newSourcesSortedByDate,
      })
    );
  };

  return { updateClaimByDate };
};

export default useDateFilter;
